import React from 'react';

import { IconSpinner, LayoutComposer } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';
import FooterContainer from '../FooterContainer/FooterContainer.js';

import { validProps } from '../PageBuilder/Field';

import css from '../PageBuilder/PageBuilder.module.css';
import StaticPage from '../PageBuilder/StaticPage.js';

import styles from '../LandingPage2/LandingPage.module.css';

import woman from '../../assets/woman-pc.webp';
import searchLanding from '../../assets/search-landing.webp';
import codeLanding from '../../assets/code-landing.webp';
import launchLanding from '../../assets/launch-landing.webp';
import settingsLanding from '../../assets/settings-landing.webp';
import moneyLanding from '../../assets/money-landing.webp';
import rocketLanding from '../../assets/rocket-landing.webp';
import girl from '../../assets/girl.webp';

const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};

//////////////////
// Page Builder //
//////////////////

/**
 * PageBuilder can be used to build content pages using page-asset.json.
 *
 * Note: props can include a lot of things that depend on
 * - pageAssetsData: json asset that contains instructions how to build the page content
 *   - asset should contain an array of _sections_, which might contain _fields_ and an array of _blocks_
 *     - _blocks_ can also contain _fields_
 * - fallbackPage: component. If asset loading fails, this is used instead.
 * - options: extra mapping of 3 level of sub components
 *   - sectionComponents: { ['my-section-type']: { component: MySection } }
 *   - blockComponents: { ['my-component-type']: { component: MyBlock } }
 *   - fieldComponents: { ['my-field-type']: { component: MyField, pickValidProps: data => Number.isInteger(data.content) ? { content: data.content } : {} }
 *     - fields have this pickValidProps as an extra requirement for data validation.
 * - pageProps: props that are passed to src/components/Page/Page.js component
 *
 * @param {Object} props
 * @returns page component
 */
const CustomLandingPage = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    listings,
    ...pageProps
  } = props;

  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }

  // Page asset contains UI info and metadata related to it.
  // - "sections" (data that goes inside <body>)
  // - "meta" (which is data that goes inside <head>)
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);

  const layoutAreas = `
    topbar
    main
    footer
  `;

  const containerStyle = {
    background: `url(${woman}),
      radial-gradient(100% 100% at 0% 0%, #578577 0%, #57857700 100%),
      radial-gradient(50% 50% at 100% 100%, #bc3800 0%, #bc380000 100%),
      radial-gradient(49% 81% at 100% -1%, #fff9f5ff 0%, #fff9f500 100%),
      radial-gradient(75% 75% at 0% 100%, #d8d7d800 0%, #d8d7d8 100%),
      radial-gradient(75% 75% at 50% 50%, #9c9c9c 0%, #9c9c9c 100%)`,
    backgroundRepeat:
      'no-repeat, repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat',
    width: '100%',
    boxShadow: '0px 29px 36px 0px rgba(0, 0, 0, 0.15)',
  };

  const containerBottomStyle = {
    background: `url(${girl}),
      radial-gradient(100% 100% at 0% 0%, #578577 0%, #57857700 100%),
      radial-gradient(50% 50% at 100% 100%, #bc3800 0%, #bc380000 100%),
      radial-gradient(49% 81% at 100% -1%, #fff9f5ff 0%, #fff9f500 100%),
      radial-gradient(75% 75% at 0% 100%, #d8d7d800 0%, #d8d7d8 100%),
      radial-gradient(75% 75% at 50% 50%, #9c9c9c 0%, #9c9c9c 100%)`,
    backgroundRepeat:
      'no-repeat, repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat',
    width: '100%',
  };

  const handleClick = e => {
    // redirect to /s page
    e.preventDefault();
    window.location.href = '/s';
  };

  const handleListing = (e, listingId, listingTitle) => {
    // redirect to /l listing page
    e.preventDefault();
    window.location.href = `/l/${listingTitle}/${listingId}`;
  };

  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Footer } = props;
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer />
              </Topbar>
              <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#FFFFFF' }}>
                {/* TOP BACKGROUND CONTAINER */}
                <div className={styles.backgroundContainer} style={containerStyle}>
                  <div className={styles.containerBox}>
                    <div className={styles.glassBox}>
                      <h1 className={styles.titleCustom}>
                        The First <br /> <b>White-Label</b> <br />
                        Software Marketplace
                      </h1>
                    </div>
                    <div className={styles.greenBox}>
                      <h2 className={styles.secondaryTitle}>
                        Find, buy, customize existing apps & more.
                      </h2>
                      <button className={styles.buttonProducts} onClick={handleClick}>
                        Browse Products
                      </button>
                    </div>
                  </div>
                </div>

                {/* LATEST PRODUCTS */}
                <div className={styles.howToContainer}>
                  <div
                    style={{
                      marginBottom: 80,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <h1 className={styles.buyTitle} style={{ padding: '0px 10px' }}>
                      Latest Products
                    </h1>
                    <p className={styles.buyDescription}>
                      Here are some of our newly listed products
                    </p>
                  </div>
                  <div className={styles.latestProductsContainer}>
                    {listings.map(listing => {
                      const listingImage =
                        listing.images[0]?.attributes?.variants?.['listing-card-2x'].url;
                      const listingTitle = listing.attributes.title;
                      const listingAuthor = listing.author.attributes.profile.displayName;
                      const listingId = listing.id.uuid;

                      const companyName =
                        listing.author.attributes?.profile?.publicData?.companyName ??
                        listingAuthor;
                      return (
                        <div
                          style={{ position: 'relative', maxWidth: 300, cursor: 'pointer' }}
                          key={listing.id.uuid}
                          onClick={e => handleListing(e, listingId, listingTitle)}
                        >
                          <span
                            className={styles.glassBox2}
                            style={{
                              marginRight: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={listingImage}
                              style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: 50,
                                padding: 10,
                              }}
                              loading="lazy"
                            />
                          </span>
                          <div
                            style={{
                              backgroundColor: '#124650',
                              borderRadius: 50,
                              height: 200,
                              color: '#fff',
                              marginTop: -80,
                              marginLeft: 10,
                              marginRight: 10,
                              alignItems: 'flex-start',
                              justifyContent: 'flex-end',
                              display: 'flex',
                              padding: 20,
                              flexDirection: 'column',
                              gap: 10,
                            }}
                          >
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 700,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                              }}
                            >
                              {listingTitle}
                            </span>
                            <span style={{ fontSize: 12, fontWeight: 400 }}>by {companyName}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* HOW TO BUY */}
                <div style={{ marginTop: '10rem' }}>
                  <div
                    style={{
                      marginBottom: 80,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <h1 className={styles.buyTitle}>How to buy</h1>
                    <p className={styles.buyDescription}>
                      Here is the place to find the software solution for your business
                    </p>
                  </div>

                  {/* DISCOVER */}
                  <div className={styles.aboutContainer}>
                    <div className={styles.coloredBox} style={{ backgroundColor: '#207584' }}>
                      <span className={styles.glassBox2}>
                        <img src={searchLanding} />
                      </span>
                    </div>
                    <div className={styles.descriptionContainer}>
                      <h1 className={styles.smallTitle}>Discover</h1>
                      <p className={styles.smallDescription1}>
                        Instead of building software from scratch, search for it in our curated
                        catalogue.
                      </p>
                      <p className={styles.smallDescription2}>
                        <b>Filter</b> by white-label software, or full source code.
                      </p>
                    </div>
                  </div>

                  {/* MAKE IT YOURS */}
                  <div className={styles.aboutContainer} style={{ transform: 'scaleX(-1)' }}>
                    <div className={styles.coloredBox} style={{ backgroundColor: '#F37021' }}>
                      <span className={styles.glassBox2}>
                        <img src={codeLanding} style={{ transform: 'scaleX(-1)' }} />
                      </span>
                    </div>
                    <div
                      className={styles.descriptionContainer}
                      style={{ transform: 'scaleX(-1)', alignItems: 'flex-end' }}
                    >
                      <h1 className={styles.smallTitle}>Make it yours</h1>
                      <p className={styles.smallDescription1}>
                        Time to market, 80% faster with flexible pricing plans and different
                        purchase methods
                      </p>
                      <ul
                        className={styles.smallDescription2}
                        style={{ listStylePosition: 'outside', paddingLeft: 15 }}
                      >
                        <li>
                          <b>Buy</b>
                        </li>
                        <li>
                          <b>Finance</b> (monthly installments)
                        </li>
                        <li>
                          <b>Lease</b> (white-label)
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* LAUNCH AND GROW */}
                  <div className={styles.aboutContainer}>
                    <div
                      className={styles.coloredBox}
                      style={{
                        backgroundColor: '#124650',
                      }}
                    >
                      <span className={styles.glassBox2}>
                        <img src={launchLanding} />
                      </span>
                    </div>
                    <div className={styles.descriptionContainer}>
                      <h1 className={styles.smallTitle}>Launch & Grow</h1>
                      <p className={styles.smallDescription1}>
                        Further customize your software directly by the software authors, with the
                        support of our built-in tools
                      </p>
                      <ul
                        className={styles.smallDescription2}
                        style={{ listStylePosition: 'outside', paddingLeft: 15 }}
                      >
                        <li>Project management tools</li>
                        <li>Legal binding service contracts</li>
                        <li>Expert support & instant deployments</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* HOW TO SELL */}
                <div
                  style={{
                    marginTop: '10rem',
                    zIndex: 3,
                    borderBottomLeftRadius: 40,
                    borderBottomRightRadius: 40,
                    backgroundColor: 'white',
                    boxShadow: '0px 29px 36px 0px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  <div
                    style={{
                      marginBottom: 80,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <h1 className={styles.buyTitle}>How to sell</h1>
                    <p className={styles.buyDescription}>
                      Monetize your software asset with or without sharing the source code.
                    </p>
                  </div>

                  {/* LIST YOUR SOFTWARE */}
                  <div className={styles.aboutContainer}>
                    <div className={styles.coloredBox} style={{ backgroundColor: '#207584' }}>
                      <span className={styles.glassBox2}>
                        <img src={settingsLanding} />
                      </span>
                    </div>
                    <div className={styles.descriptionContainer}>
                      <h1 className={styles.smallTitle}>List your software</h1>
                      <p className={styles.smallDescription1}>
                        Increase your software awareness and business exposure.
                      </p>
                      <p className={styles.smallDescription2}>
                        Effortlessly generate revenue whether you have a team of developers,
                        marketers, or sales professionals or not.
                      </p>
                    </div>
                  </div>

                  {/* EARN */}
                  <div className={styles.aboutContainer} style={{ transform: 'scaleX(-1)' }}>
                    <div className={styles.coloredBox} style={{ backgroundColor: '#F37021' }}>
                      <span className={styles.glassBox2}>
                        <img src={moneyLanding} style={{ transform: 'scaleX(-1)' }} />
                      </span>
                    </div>
                    <div
                      className={styles.descriptionContainer}
                      style={{ transform: 'scaleX(-1)', alignItems: 'flex-end' }}
                    >
                      <h1 className={styles.smallTitle}>Earn</h1>
                      <p className={styles.smallDescription1}>
                        <b>Sell</b> or <b>Lease</b> globally without undercutting your core customer
                        base, by “Franchising” to non-competitive regions.
                      </p>
                      <p className={styles.smallDescription2}>
                        <b>CodeHost</b> provides all the tools you need to monetize your software
                        product and even your business model.
                      </p>
                    </div>
                  </div>
                  {/* GROW */}
                  <div className={styles.aboutContainer} style={{ marginBottom: '100px' }}>
                    <div
                      className={styles.coloredBox}
                      style={{
                        backgroundColor: '#124650',
                      }}
                    >
                      <span className={styles.glassBox2}>
                        <img src={rocketLanding} />
                      </span>
                    </div>
                    <div className={styles.descriptionContainer}>
                      <h1 className={styles.smallTitle}>Grow</h1>
                      <p className={styles.smallDescription1}>
                        Access new ways to increase profit.
                        <br />
                        <br />
                        Employ our pioneering platform to securely lease your software, unlocking
                        new opportunities to generate more revenue and offering additional services
                        to your buyers.
                      </p>
                      <ul
                        className={styles.smallDescription2}
                        style={{ listStylePosition: 'outside', paddingLeft: 15 }}
                      >
                        <li>Manage deployments</li>
                        <li>Collaborate with other users</li>
                        <li>Manage your clients and projects better and more efficiently</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* BOTTOM BACKGROUND CONTAINER */}
                <div className={styles.bottomContainer}>
                  <div
                    className={`${styles.backgroundContainer} ${styles.bottomInverse}`}
                    style={{
                      ...containerBottomStyle,
                      marginTop: -40,
                      zIndex: 2,
                      height: '50rem',
                    }}
                  >
                    <div className={styles.containerBox}>
                      <div className={styles.glassBox} style={{ paddingLeft: 0 }}>
                        <h1 className={styles.titleCustom} style={{ width: 'min-content' }}>
                          <b>Empowering Digital Evolution</b>
                        </h1>
                      </div>
                      <div
                        className={styles.greenBox}
                        style={{ alignItems: 'flex-start', height: '160%', top: '130%' }}
                      >
                        <h2 className={styles.secondaryTitle}>
                          Crafting a future where software solutions are accessible, adaptable, and
                          transformative for every business need.
                        </h2>
                        <span className={styles.textButton}>Check back soon for updates</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer>
                <FooterContainer />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { LayoutComposer, StaticPage };

export default CustomLandingPage;
// newly listed products
